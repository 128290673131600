import React, { useState } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Button from "../../components/button/button";
import HeaderLight from "../../components/header/headerLight/headerLight";

import { submit } from "../../utils/submit";
import ReCAPTCHA from "react-google-recaptcha"; 

import "./style.scss";
import InputDate from "../../shared/ui/inputDate";
import NumberFormat from "react-number-format";
import {navigate} from "gatsby";
import InputTelephone from "../../shared/ui/inputTelephone";
import InputSirenSiret from "../../shared/ui/inputSirenSiret";
import InputMail from "../../shared/ui/inputMail";
import FormError from "../../components/formError";
import ChampsObligatoiresMention from "../../components/champsObligatoiresMention";

const DevenirPartenaire = ({ location }) => {

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [infoForm, setInfoForm] = useState({code: "devenir-partenaire"});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInfoForm((prevInfo) => ({
      ...prevInfo,
      [e.target.name]: e?.target?.value?.trim(),
    }));
  };

  const handleNumberFormattedInput = (values, sourceInfo) => {
    setInfoForm((prevInfo) => ({
      ...prevInfo,
      [sourceInfo.event.target.name]: values?.value?.trim(),
    }));
  }

  const isAllowedMax = (values, max) => {
    return values?.value <= max;
  }

  const handleError = () => {
    setFormError(true);
    document.getElementById("form-error")?.scrollIntoView();
  }

  return (
    <>
      <Helmet htmlAttributes={{
        class: "no-scrolling"
      }}>
        <body className="devenir-partenaire" />
      </Helmet>
      <Layout>
        <SEO title="Devenir Partenaire" />
        <HeaderLight
          title={
            <>
              Devenir <strong>partenaire de L'Auxiliaire</strong>
            </>
          }
          location={location}
          crumbLabel="Devenir partenaire"
        />
        <section className="devenir-partenaire__section">
          <p>
            Vous souhaitez <strong>distribuer nos contrats</strong>&nbsp;? Nous vous
            remercions de remplir le formulaire ci-dessous&nbsp;!
          </p>
        </section>
        <form
          className="form devenir-partenaire__section"
          onSubmit={(e) => {
            e.preventDefault();
            if(!isLoading && isCaptchaValid) {
              setIsLoading(true);
              submit(infoForm).then((response) => {
                if(response?.ok) {
                  navigate("/merci-pour-votre-demande");
                } else {
                 handleError();
                }
                setIsLoading(false);
              }, () => {
                handleError();
                setIsLoading(false);
              });
            }
          }}
        >
          {formError && <FormError />}
          <div>
            <input
              type="text"
              name="raison-sociale"
              id="raison-sociale"
              placeholder="Raison sociale *"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="date-creation">date de création du cabinet</label>
            <InputDate
                inputId="date-creation"
                inputName="date-creation"
                inputRequired={true}
                inputPlaceholder="sélectionner la date *"
                onChange={handleChange}
            />
          </div>
          <div>
            <span>ainsi que votre</span>
            <input
              type="number"
              name="code-postal"
              id="code-postal"
              max={99999}
              onInput={(e) => e.target.value = e.target.value.slice(0, 5)}
              placeholder="code postal *"
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="ville"
              id="ville"
              placeholder="ville *"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <span>votre</span>
            <InputSirenSiret
                inputId="siret"
                inputName="siret"
                inputPlaceholder="numéro de SIRET *"
                inputRequired={true}
                inputHandleChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="prenom"
              id="prenom"
              placeholder="prénom du representant légal *"
              onChange={handleChange}
              required
            />
            <span>et</span>
            <input
              type="text"
              name="nom"
              id="nom"
              placeholder="son nom *"
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <span>votre</span>
            <InputMail
                inputName="mail"
                inputId="mail"
                inputPlaceholder="adresse email *"
                inputRequired={true}
                inputHandleChange={handleChange}
            />
          </div>
          <div>
            <span>votre</span>
            <InputTelephone
                inputName="tel"
                inputId="tel"
                inputPlaceholder="numéro de téléphone *"
                inputHandleChange={handleChange}
                inputRequired={true}
            />
          </div>
          <div>
            <span>Votre effectif de salariés</span>
            <input
              type="number"
              name="effectif"
              id="effectif"
              placeholder="nombre *"
              onChange={handleChange}
              required
              className="flex-custom-input"
              max={100000}
            />
          </div>
          <div className="form__radio u-mt-big">
            <p className="form__radio-question">
              Possédez-vous d'autres points de vente&nbsp;? *
            </p>
            <div className="form__radio-container">
              <div>
                <input
                  type="radio"
                  name="autre-point-vente"
                  id="autre-point-vente-yes"
                  value="oui"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="autre-point-vente-yes">oui</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="autre-point-vente"
                  id="autre-point-vente-no"
                  onChange={handleChange}
                  value="non"
                  required
                />
                <label htmlFor="autre-point-vente-no">non</label>
              </div>
            </div>
          </div>
          <div className="form__radio">
            <p className="form__radio-question">Êtes-vous agent général&nbsp;? *</p>
            <div className="form__radio-container">
              <div>
                <input
                  type="radio"
                  name="agent-general"
                  id="agent-general-yes"
                  onChange={handleChange}
                  value="oui"
                  required
                />
                <label htmlFor="agent-general-yes">oui</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="agent-general"
                  id="agent-general-no"
                  onChange={handleChange}
                  value="non"
                  required
                />
                <label htmlFor="agent-general-no">non</label>
              </div>
            </div>
          </div>
          <div className="form__radio">
            <p className="form__radio-question">
              Adhérez-vous à un syndicat professionnel&nbsp;? *
            </p>
            <div className="form__radio-container">
              <div>
                <input
                  type="radio"
                  name="syndicat-professionnel"
                  id="syndicat-professionnel-yes"
                  value="oui"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="syndicat-professionnel-yes">oui</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="syndicat-professionnel"
                  id="syndicat-professionnel-no"
                  value="non"
                  onChange={handleChange}
                  required
                />
                <label htmlFor="syndicat-professionnel-no">non</label>
              </div>
            </div>
          </div>

          <div className="u-mt-big">
            <label htmlFor="compagnies">
              Avec quelles compagnies travaillez-vous habituellement&nbsp;?
            </label>
            <textarea
              name="compagnies"
              id="compagnies"
              cols="30"
              rows="10"
              placeholder="Texte libre..."
              onChange={handleChange}
            />
          </div>

          <div className="u-mt-big">
            <span>Quelle est la part en </span>
            <NumberFormat
                allowNegative={false}
                name="part-activite"
                id="part-activite"
                placeholder="pourcentage %"
                suffix=" %"
                isAllowed={(values) => isAllowedMax(values, 100)}
                decimalScale={0}
                required
                onValueChange={handleNumberFormattedInput}
                className="flex-custom-input"
            />
            <span>de l'activité construction dans votre portefeuille&nbsp;? *</span>
          </div>

          <div className="u-mt-big">Aujourd'hui, vous possédez,</div>
          <div>
            <input
              type="number"
              name="nbr-clients-pro-iard"
              id="nbr-clients-pro-iard"
              placeholder="nombre *"
              onChange={handleChange}
              required
              className="flex-custom-input"
            />
            <span>
              clients <strong>professionnels</strong> IARD,
            </span>
          </div>
          <div>
            <span>pour un</span>
            <NumberFormat
                name="montant-prime-pro-iard"
                id="montant-prime-pro-iard"
                placeholder="montant € *"
                required
                className="flex-custom-input"
                suffix=" €"
                decimalScale={0}
                thousandSeparator={" "}
                allowNegative={false}
                onValueChange={handleNumberFormattedInput}
            />
            <span>de primes annuelles, avec</span>
            <NumberFormat
              name="percent-portefeuille-pro-iard"
              id="percent-portefeuille-pro-iard"
              placeholder="pourcentage % *"
              required
              className="flex-custom-input"
              suffix=" %"
              decimalScale={0}
              allowNegative={false}
              onValueChange={handleNumberFormattedInput}
              isAllowed={(values) => isAllowedMax(values, 100)}
            />
            <span>du portefeuille des clients professionnels IARD sur le CA total du cabinet.</span>
          </div>

          <div className="u-mt-big">
            <span>Avec nous, vous pensez pouvoir réaliser</span>
            <NumberFormat
              name="volume-affaires"
              id="volume-affaires"
              placeholder="volume affaires € *"
              required
              suffix=" €"
              decimalScale={0}
              thousandSeparator={" "}
              allowNegative={false}
              onValueChange={handleNumberFormattedInput}
            />
          </div>
          <div className="u-mt-big">
            <label htmlFor="raisons">
              Pour quelles raisons souhaitez-vous travailler avec nous&nbsp;?
            </label>
            <textarea
              name="raisons"
              id="raisons"
              cols="30"
              rows="10"
              placeholder="Texte libre..."
              onChange={handleChange}
            />
          </div>
          <ChampsObligatoiresMention showFinaliteTraitementDonnees={true} />
          <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(val)=>{setIsCaptchaValid(!!val)}}
          />
          <Button
            className="devenir-partenaire__btn"
            type="submit"
            text="Envoyer"
            isDisabled={!isCaptchaValid}
            isLoading={isLoading}
          />
        </form>
      </Layout>
    </>
  );
};

export default DevenirPartenaire;
