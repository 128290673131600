import React, {useRef, useState} from "react";

import './index.scss';

export default function InputDate({inputClass, inputId, inputName, inputPlaceholder, inputRequired = false, onChange}) {
    const inputRef = useRef();
    const [type, setType] = useState('text');

    const checkDateValidity = (e) => {
        if(e?.target?.value && new Date(e.target.value).getTime() > Date.now()) {
            e.target.setCustomValidity("La date saisie ne peut pas être supérieure à la date actuelle");
        } else {
            e.target.setCustomValidity("");
        }
    }

    const handleCalendarPicker = () => {
        setType("date");
        const input = document.querySelector(`input[id=${inputId}]`);
        input.type = "date";
        if(input.showPicker) {
            input.showPicker();
        }
    }

    return (
        <div className="input__datepicker-toggle">
            <input
                type={type}
                name={inputName}
                id={inputId}
                placeholder={inputPlaceholder}
                onChange={onChange}
                required={inputRequired}
                className={inputClass}
                onFocus={() => setType('date')}
                onInput={checkDateValidity}
                ref={inputRef}
            />
            <span className="input__datepicker-toggle-button" onClick={handleCalendarPicker}></span>
        </div>
    );
}
